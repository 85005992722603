import { useCallback, useState } from 'react'

import {
  ActionIcon,
  Box,
  CloseButton,
  Tooltip,
  Button as MantineButton,
} from '@mantine/core'

import { navigate, routes } from '@redwoodjs/router'

import IconCheckmarkCircle from 'src/icons/IconCheckmarkCircle'
import SMSInput from 'src/pages/ApplicantDetailsPage/tabs/ActivityLogTab/components/ActivityLogInput/SMSInput/SMSInput'

import AttachmentsMenu from '../Overlays/AttachmentsMenu/AttachmentsMenu'
import dayjs from 'dayjs'
import { useAuth } from 'src/auth'

const NotificationCard = ({
  notification,
  updateCompanyAndRedirect,
  markAsRead,
  onReplySMS,
}) => {
  const [isReplying, setIsReplying] = useState(false)
  const [hover, setHover] = useState(false)
  const { currentUser } = useAuth()
  const applicantExists = notification.applicant

  const title = applicantExists
    ? notification.applicant.firstName + ' ' + notification.applicant.lastName
    : notification.title

  const isNotificationFromDifferentCompany =
    notification?.company &&
    currentUser.companyId !== notification?.company?.companyId

  const handleApplicantNavigate = useCallback(() => {
    if (applicantExists) {
      const route =
        notification.type === 'ORDER_UPDATE'
          ? routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'documents',
              subtab: 'documents',
            })
          : routes.applicantDetails({
              id: notification.applicant.applicantId,
              tab: 'application',
            })

      if (isNotificationFromDifferentCompany) {
        updateCompanyAndRedirect(notification.employeeId, route)
      } else {
        navigate(route)
      }
    }
  }, [applicantExists, notification])

  const onHover = useCallback(() => {
    setHover(true)
  }, [])

  const onLeave = useCallback(() => {
    setHover(false)
  }, [])

  return (
    <div
      className="group relative flex flex-col gap-2 border-b-[1px] border-doubleNickel-gray-200 p-3 hover:bg-doubleNickel-gray-50"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-row items-center gap-1">
            <Tooltip label={title}>
              <Box
                className={`truncate text-sm font-medium text-doubleNickel-gray-900 ${
                  applicantExists &&
                  'hover:cursor-pointer hover:text-doubleNickel-brand-500 hover:underline'
                }`}
                onClick={() => handleApplicantNavigate()}
              >
                {title}
              </Box>
            </Tooltip>
            {isNotificationFromDifferentCompany && (
              <>
                <div className="text-sm text-doubleNickel-gray-700">via</div>
                <div
                  className="text-sm font-medium text-doubleNickel-brand-500 hover:cursor-pointer hover:underline"
                  onClick={() =>
                    updateCompanyAndRedirect(
                      notification.employeeId,
                      routes.dashboard()
                    )
                  }
                >
                  {notification.company?.companyName}
                </div>
              </>
            )}
          </div>

          <div className="ml-auto flex flex-row items-center gap-4">
            <Tooltip label="Mark as read">
              <ActionIcon
                variant="transparent"
                onClick={() =>
                  markAsRead({ variables: { id: notification.notificationId } })
                }
              >
                <IconCheckmarkCircle
                  className={`fill-none hover:stroke-doubleNickel-brand-500 ${
                    notification.read
                      ? 'stroke-doubleNickel-brand-500'
                      : 'stroke-doubleNickel-gray-300'
                  }`}
                />
              </ActionIcon>
            </Tooltip>
          </div>
        </div>
        <div className="text-xs text-doubleNickel-gray-600">
          {dayjs(notification.createdAt).format('MM/DD/YYYY hh:mm A')}
        </div>
      </div>
      <div
        className={`text-sm text-doubleNickel-gray-700 ${
          hover ? 'line-clamp-none' : 'line-clamp-1'
        }`}
      >
        {notification.message}
      </div>
      {notification.type === 'SMS' &&
        notification.activityLog?.documents &&
        notification.activityLog?.documents.length > 0 && (
          <AttachmentsMenu
            disabled={isNotificationFromDifferentCompany}
            disabledText="Switch companies to open attachments"
            attachments={notification.activityLog.documents}
            applicantId={notification.applicant?.applicantId}
          />
        )}
      <div className="flex flex-row justify-between">
        {notification.type === 'VOE_RESPONSE' && hover && (
          <div className="mt-3 flex flex-row items-center gap-2">
            {isNotificationFromDifferentCompany ? (
              <>
                <div>
                  <Tooltip
                    label={`This action will switch you to your ${notification.company?.companyName} account`}
                  >
                    <MantineButton
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        const employeeId = notification.employeeId
                        const route = routes.voeResponseDetails({
                          id: notification.applicant.applicantId,
                          tab: 'voe',
                          voeId: notification.voe.voeId,
                        })
                        updateCompanyAndRedirect(employeeId, route)
                      }}
                    >
                      Switch & View
                    </MantineButton>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div>
                <MantineButton
                  size="xs"
                  onClick={() =>
                    navigate(
                      routes.voeResponseDetails({
                        id: notification.applicant.applicantId,
                        tab: 'voe',
                        voeId: notification.voe.voeId,
                      })
                    )
                  }
                  variant="filled"
                >
                  View
                </MantineButton>
              </div>
            )}
          </div>
        )}
        {!isReplying && notification.type === 'SMS' && hover && (
          <div className="mt-3 flex flex-row items-center gap-2">
            {isNotificationFromDifferentCompany ? (
              <>
                <div>
                  <Tooltip
                    label={`This action will switch you to your
                  ${notification.company?.companyName} account`}
                  >
                    <MantineButton
                      size="xs"
                      onClick={() => {
                        const employeeId = notification.employeeId
                        const route = routes.applicantDetails({
                          id: notification.applicant?.applicantId,
                          tab: 'activitylog',
                          activityType: 'SMS',
                        })
                        updateCompanyAndRedirect(employeeId, route)
                      }}
                      variant="filled"
                    >
                      Reply From {notification.company?.companyName}
                    </MantineButton>
                  </Tooltip>
                </div>
              </>
            ) : (
              <div>
                <MantineButton
                  size="xs"
                  onClick={() => setIsReplying(!isReplying)}
                  variant="filled"
                >
                  Reply
                </MantineButton>
              </div>
            )}
          </div>
        )}
      </div>
      {isReplying && (
        <div className="flex flex-row items-end gap-2">
          <div className="flex-1">
            <SMSInput
              applicantId={notification.applicant?.applicantId}
              sendSMS={({ content }) =>
                onReplySMS({
                  notificationId: notification.notificationId,
                  message: content,
                })
              }
            />
          </div>
          <CloseButton
            className="mb-2"
            size="sm"
            onClick={() => setIsReplying(false)}
          />
        </div>
      )}
    </div>
  )
}

export default NotificationCard
