import { useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { Loader, Skeleton } from '@mantine/core'
import { useInView } from 'react-intersection-observer'

import { navigate } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import {
  GET_NOTIFICATIONS,
  MARK_AS_READ,
  REPLY_SMS,
} from 'src/graphql/notifications.graphql'
import {
  GET_MY_COMPANIES,
  UPDATE_CURRENT_EMPLOYEE_ID,
} from 'src/graphql/users.graphql'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import { useWebsockets } from 'src/pages/ApplicantsPage/context/WebSocketsContext'

import { toast } from '../Overlays/Toast/Toast'

import NotificationCard from './NotificationCard'

const NotificationTab = ({
  currentTab,
  setTotalNotifications,
  setNotificationsLoading,
}) => {
  const [notifications, setNotifications] = useState([])

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const websocketsData = useWebsockets()

  const LIMIT = 10
  const page = 1

  const { data, refetch, fetchMore, networkStatus, loading } = useQuery(
    GET_NOTIFICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
        filters: { types: [currentTab] },
      },
      onCompleted: (data) => {
        setNotifications(data?.notifications.items)
        setTotalNotifications(data?.notifications.totalCount)
      },
      onError: () => {
        toast('Something went wrong, please try again.', 'error')
      },
    }
  )
  //Refetch notifications when tab changes
  useEffect(() => {
    if (currentTab) {
      refetch()
    }
  }, [currentTab, refetch])

  //Update parent loading state
  useEffect(() => {
    setNotificationsLoading(loading)
  }, [loading, setNotificationsLoading])

  const [markAsRead] = useMutation(MARK_AS_READ, {
    onCompleted: () => {
      refetch()
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const [replySMS] = useMutation(REPLY_SMS, {
    refetchQueries: [{ query: GET_NOTIFICATIONS }],
    onCompleted: () => {
      toast('Message sent', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const [updateCurrentEmployeeId] = useMutation(UPDATE_CURRENT_EMPLOYEE_ID, {
    onCompleted: (data) => {
      toast('Company changed successfully', 'success')
    },
    onError: () => {
      toast('Unable to switch companies. Please try again.', 'error')
    },
  })

  const updateCompanyAndRedirect = async (employeeId, route) => {
    await updateCurrentEmployeeId({
      variables: {
        input: {
          currentEmployeeId: employeeId,
        },
      },
    })
    navigate(route)
    window.location.reload()
  }

  const onReplySMS = ({ notificationId, message }) => {
    replySMS({
      variables: {
        id: notificationId,
        message,
      },
    })
  }

  useEffect(() => {
    if (websocketsData?.newNotification) {
      if (currentTab == websocketsData?.newNotification?.type) {
        refetch()
      }
    }
  }, [currentTab, refetch, websocketsData])

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: notifications.length ?? 0,
            limit: LIMIT,
          },
        },
      })
    }
  }, [inView])

  const NotificationSkeleton = () => {
    return (
      <div className="flex flex-1 flex-col gap-2 rounded-lg border p-4">
        <Skeleton height={20} width={'50%'} />
        <Skeleton height={40} />
        <Skeleton height={20} width="25%" ml={'auto'} />
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col overflow-auto">
      {loading &&
        Array(5)
          .fill(0)
          .map((_, index) => <NotificationSkeleton key={index} />)}
      {!loading && notifications.length === 0 && (
        <div className="flex flex-1 flex-col items-center justify-center  ">
          <IconEmptyInbox className="fill-none" />
          <div className="text-md font-medium text-doubleNickel-gray-600">
            You have no new notifications
          </div>
        </div>
      )}
      {!loading &&
        notifications.map((notification) => (
          <NotificationCard
            key={notification.notificationId}
            notification={notification}
            updateCompanyAndRedirect={updateCompanyAndRedirect}
            markAsRead={markAsRead}
            onReplySMS={onReplySMS}
          />
        ))}
      {networkStatus === NetworkStatus.fetchMore && <Loader size="sm" />}
      {notifications.length < data?.notifications.totalCount && !loading && (
        <div ref={ref} className="opacity-0">
          Inview placeholder
        </div>
      )}
    </div>
  )
}

export default NotificationTab
