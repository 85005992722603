import { useEffect, useState, useCallback } from 'react'

import { Badge, Tabs } from '@mantine/core'

import { WebSocketsActionType } from 'src/pages/ApplicantsPage/context/websockets.reducer'
import {
  useWebsockets,
  useWebsocketsDispatch,
} from 'src/pages/ApplicantsPage/context/WebSocketsContext'

import NotificationTab from './NotificationTab'

const Notifications = () => {
  const websocketContext = useWebsockets()
  const websocketDispatch = useWebsocketsDispatch()
  const [currentTab, setCurrentTab] = useState('SMS')
  const [totalNotifications, setTotalNotifications] = useState(0)
  const [notificationsLoading, setNotificationsLoading] = useState(false)

  useEffect(() => {
    if (
      websocketContext?.newNotification?.type &&
      ['SMS', 'VOE_RESPONSE', 'ORDER_UPDATE'].includes(
        websocketContext?.newNotification?.type
      )
    ) {
      setCurrentTab(websocketContext?.newNotification?.type)
      websocketDispatch({
        type: WebSocketsActionType.CLEAR_NEW_NOTIFICATION,
      })
    }
  }, [websocketContext])

  const onTabClick = useCallback((value) => {
    setCurrentTab(value)
  }, [])

  const MENU = {
    SMS: 'Texts',
    VOE_RESPONSE: 'Verification',
    ORDER_UPDATE: 'Orders',
  }

  return (
    <div className="flex h-[100%] flex-col gap-4 overflow-hidden">
      <div className="text-base font-semibold text-doubleNickel-gray-900">
        Notifications
      </div>
      <Tabs
        radius="xs"
        value={currentTab}
        onChange={onTabClick}
        classNames={{
          root: 'h-full',
          tab: '[&[data-active]]:border-doubleNickel-brand-400 ',
          panel: 'mt-2 mb-10 overflow-hidden h-[90%]',
        }}
      >
        <Tabs.List>
          {Object.entries(MENU).map(([key, value]) => (
            <Tabs.Tab
              key={key}
              value={key}
              rightSection={
                currentTab === key &&
                !notificationsLoading && (
                  <Badge
                    variant="light"
                    className={`rounded-lg border bg-doubleNickel-brand-50 text-doubleNickel-brand-500`}
                  >
                    {totalNotifications}
                  </Badge>
                )
              }
            >
              {value}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {Object.keys(MENU).map((key) => (
          <Tabs.Panel key={key} value={key}>
            <NotificationTab
              currentTab={currentTab}
              setTotalNotifications={setTotalNotifications}
              setNotificationsLoading={setNotificationsLoading}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  )
}

export default Notifications
