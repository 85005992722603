import { ActionIcon, CloseButton, Button as MantineButton } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import DocumentCategoryMenu from 'src/components/Overlays/DocumentCategoryMenu/DocumentCategoryMenu'
import IconDocumentCheck from 'src/icons/IconDocumentCheck'
import IconDownload from 'src/icons/IconDownload'
import IconEdit from 'src/icons/IconEdit'
import IconLeftArrow from 'src/icons/IconLeftArrow'
import IconPrint from 'src/icons/IconPrint'

const DocumentPreviewHeader = ({
  documentId,
  fileName,
  category,
  refetchQueries,
  showStatus,
  showBackButton,
  showClosebutton,
  setEditMode,
  onBack,
  onPrint,
  onDownload,
  onApprove,
  showActions,
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex w-96 flex-1 flex-row items-center gap-4 overflow-hidden">
        {showBackButton && (
          <ActionIcon variant="subtle" className="h-6 w-6" onClick={onBack}>
            <IconLeftArrow className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
          </ActionIcon>
        )}
        <div className="truncate text-lg font-bold text-doubleNickel-gray-600">
          {fileName}
        </div>
        {showStatus && (
          <DocumentCategoryMenu
            documentId={documentId}
            category={category}
            refetchQueries={refetchQueries}
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-4 ">
        {showActions && (
          <Button
            variant="outline"
            text="Approve"
            lefticon={<IconDocumentCheck />}
            onClick={onApprove}
          />
        )}
        <Button
          variant="outline"
          text="Print"
          lefticon={<IconPrint />}
          onClick={onPrint}
        />
        <Button
          variant="outline"
          text="Download"
          lefticon={<IconDownload />}
          onClick={onDownload}
        />
        <Button
          variant="outline"
          text="Edit"
          lefticon={<IconEdit />}
          onClick={() => setEditMode(true)}
        />
        {showClosebutton && (
          <div className="ml-autp">
            <CloseButton onClick={onBack} />
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentPreviewHeader
