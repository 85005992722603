import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ActionIcon, Divider, Box, Loader, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import Draggable from 'react-draggable'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import {
  CallStatus,
  usePhoneDevice,
  usePhoneDeviceDispatch,
} from 'src/context/PhoneDeviceContext'
import { GET_ACTIVE_CALL } from 'src/graphql/activityLogs.graphql'
import { GET_TWILIO_ACCESS_TOKEN } from 'src/graphql/twilio.graphql'
import { PLAY_VOICEMAIL_IN_ACTIVE_CALL } from 'src/graphql/voiceMailTemplate.graphql'
import IconHangup from 'src/icons/IconHangup'
import IconMicrophone from 'src/icons/IconMicrophone'
import IconPhoneCall from 'src/icons/IconPhoneCall'
import IconPlay from 'src/icons/IconPlay'
import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'

import VoiceMailTemplateSelect from '../../Combobox/VoiceMailTemplateSelect/VoiceMailTemplateSelect'
import MicPermissionDeniedModal from '../MicPermissionDeniedModal/MicPermissionDeniedModal'
import { toast } from '../Toast/Toast'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const CallCancelButton = ({ onCall, onCancel }) => {
  return (
    <div className="absolute bottom-0 right-0 top-0 flex w-[20%] flex-col border-l">
      <Box
        onClick={onCall}
        className="flex flex-1 cursor-pointer flex-row items-center justify-center gap-1 text-sm font-semibold text-doubleNickel-brand-500 hover:bg-doubleNickel-brand-25"
      >
        <IconPhoneCall className="h-4 fill-none stroke-doubleNickel-brand-500" />
        Call
      </Box>
      <Divider className="border-b-1 w-full border-doubleNickel-gray-200" />
      <Box
        onClick={onCancel}
        className="flex flex-1 cursor-pointer flex-col justify-center text-center text-sm font-semibold text-doubleNickel-gray-600 hover:bg-doubleNickel-brand-25"
      >
        Cancel
      </Box>
    </div>
  )
}

const HangupMuteButton = ({ onHangup, onMute }) => {
  const [isMuted, setIsMuted] = useState(false)

  const handleMute = () => {
    setIsMuted(!isMuted)
    onMute()
  }
  return (
    <div className="flex flex-row gap-4">
      <ActionIcon
        onClick={handleMute}
        radius="xl"
        variant="outline"
        classNames={{
          root: `border ${
            isMuted
              ? 'border-doubleNickel-error-500'
              : 'border-doubleNickel-gray-400'
          }`,
        }}
      >
        <IconMicrophone
          className={`fill-none ${
            isMuted
              ? 'stroke-doubleNickel-error-500'
              : 'stroke-doubleNickel-gray-400'
          } `}
        />
      </ActionIcon>
      <ActionIcon onClick={onHangup} radius="xl" variant="filled" color="red">
        <IconHangup fill="none" stroke="white" />
      </ActionIcon>
    </div>
  )
}

const CallStatusDisplay = ({ callState, toNumber, currentTime }) => {
  return (
    <>
      <p className="text-xs font-medium capitalize text-doubleNickel-gray-700">
        {callState === CallStatus.INITIATED
          ? `Calling ${formatInternationalPhoneNumber(toNumber)}`
          : callState === CallStatus.RINGING
          ? `Ringing: ${formatInternationalPhoneNumber(toNumber)}`
          : callState === CallStatus.IN_PROGRESS
          ? `${formatTime(currentTime)}`
          : callState === CallStatus.NO_ANSWER
          ? `No answer`
          : callState === CallStatus.COMPLETED
          ? `Completed`
          : callState === CallStatus.BUSY
          ? `Busy`
          : callState === CallStatus.LEAVING_VOICEMAIL
          ? `No answer - Leaving voicemail`
          : callState === CallStatus.LEFT_VOICEMAIL
          ? `No answer - Voicemail left`
          : callState === CallStatus.FAILED
          ? `Call failed, please check the phone number`
          : null}
      </p>
    </>
  )
}

const LeaveVoicemailButton = ({
  showPlayVoicemailButton,
  onPlayVoiceMail,
  phoneDeviceContext,
}) => {
  return (
    <div
      className={`flex flex-row items-center gap-1 ${
        showPlayVoicemailButton ? 'visible' : 'invisible'
      }`}
    >
      <Box
        onClick={() => onPlayVoiceMail()}
        className="rounded-lg hover:bg-doubleNickel-brand-25"
      >
        <IconPlay className="h-3 fill-none stroke-doubleNickel-brand-500" />
      </Box>
      {phoneDeviceContext.callState === CallStatus.LEAVING_VOICEMAIL ? (
        <>
          <Loader color="blue" type="bars" size={12} />
          <Loader color="blue" type="bars" size={12} />
        </>
      ) : (
        <div className="text-xs font-medium text-doubleNickel-gray-500 ">
          Play voicemail
        </div>
      )}
    </div>
  )
}

const formatTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0'
  )}`
}

export const PhoneDeviceModal = () => {
  const phoneDeviceContext = usePhoneDevice()
  const dispatch = usePhoneDeviceDispatch()
  const { currentUser } = useAuth()
  const [startTime, setStartTime] = useState(null)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [selectedVoiceMailTemplateId, setSelectedVoiceMailTemplateId] =
    useState(null)
  const [openedInstructions, { open: openInstructionsModal, close }] =
    useDisclosure(false)

  const [getAccessToken] = useLazyQuery(GET_TWILIO_ACCESS_TOKEN)
  const [getActiveCall] = useLazyQuery(GET_ACTIVE_CALL)
  const [playVoiceMail, { loading: loadingVoicemail }] = useMutation(
    PLAY_VOICEMAIL_IN_ACTIVE_CALL,
    {
      onCompleted: ({ playVoiceMailInActiveCall }) => {
        if (!playVoiceMailInActiveCall.sent) {
          toast('There was an error trying to sending the voicemail', 'warning')
        }
      },
      onError,
    }
  )

  const [lastActivityLogId, setLastActivityLogId] = useState(null)

  // Timer to update call duration
  useEffect(() => {
    let timer = null
    if (
      phoneDeviceContext?.callState === CallStatus.IN_PROGRESS &&
      startTime &&
      phoneDeviceContext?.phoneDevice?.isBusy
    ) {
      timer = setInterval(() => {
        setElapsedTime(new Date().getTime() - startTime)
      }, 1000)
    } else {
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [phoneDeviceContext, startTime])

  /**
   * Use Effect to handle CallStatus changes
   * If the call is INITIATED and the phoneDevice is busy, set the lastActivityLogId using the activityLogId from CallChannel.
   * In this way we can later ignore events that don't belong to the current call.
   * If the call is IN_PROGRESS and the phoneDevice is busy, set the startTime to calculate the call duration.
   * If the call is in an end state and the lastActivityLogId is the same as the current activityLogId, reset the startTime and elapsedTime. This to only change statuses and counters if this is the active call or it was.
   */
  useEffect(() => {
    if (
      phoneDeviceContext?.phoneDevice?.isBusy &&
      phoneDeviceContext?.callState === CallStatus.INITIATED &&
      phoneDeviceContext?.activityLogId
    ) {
      setLastActivityLogId(phoneDeviceContext?.activityLogId)
      return
    }

    if (
      startTime === null &&
      phoneDeviceContext?.callState === CallStatus.IN_PROGRESS &&
      phoneDeviceContext.phoneDevice.isBusy
    ) {
      setStartTime(new Date().getTime())
    }

    const isEndState =
      [
        CallStatus.COMPLETED,
        CallStatus.NO_ANSWER,
        CallStatus.BUSY,
        CallStatus.LEFT_VOICEMAIL,
        CallStatus.FAILED,
      ].includes(phoneDeviceContext?.callState) ||
      (phoneDeviceContext?.callState === CallStatus.LEAVING_VOICEMAIL &&
        selectedVoiceMailTemplateId != null)

    if (isEndState && lastActivityLogId === phoneDeviceContext?.activityLogId) {
      setStartTime(null)
      setElapsedTime(0)
      setTimeout(() => {
        dispatch({
          type: PhoneDeviceActionType.SET_CALL_STATE,
          payload: {
            callState: null,
          },
        })
      }, 1000)
    }
  }, [
    phoneDeviceContext?.callState,
    phoneDeviceContext?.activityLogId,
    lastActivityLogId,
    phoneDeviceContext?.phoneDevice?.isBusy,
    startTime,
  ])

  const onCall = useCallback(async () => {
    const { data: activeCallData } = await getActiveCall()
    if (activeCallData.getActiveCall) {
      toast('There is an active call', 'warning')
      return
    }

    // Update the token everytime it calls to prevent expiration errors.
    const { data } = await getAccessToken()
    const { accessToken } = data.getTwilioAccessToken
    phoneDeviceContext.phoneDevice.updateToken(accessToken)

    const call = await phoneDeviceContext.phoneDevice.connect({
      params: {
        To: phoneDeviceContext.phone,
        applicantId: phoneDeviceContext.applicantId,
        employeeId: currentUser.employeeId,
        ...(selectedVoiceMailTemplateId && {
          voiceMailTemplateId: selectedVoiceMailTemplateId,
        }),
      },
      rtcConstraints: {
        audio: true,
      },
    })
    dispatch({
      type: PhoneDeviceActionType.CALL,
      payload: {
        call,
        callState: CallStatus.INITIATED,
      },
    })
    call.on('error', (error) => {
      if (error.name === 'PermissionDeniedError' && error.code === 31401) {
        openInstructionsModal()
      }
      console.error(error)
      setLastActivityLogId(null)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneDeviceContext, currentUser, selectedVoiceMailTemplateId])

  const onCancel = useCallback(() => {
    dispatch({
      type: PhoneDeviceActionType.CLOSE_DEVICE_MODAL,
    })
  }, [])

  const onHangup = useCallback(() => {
    phoneDeviceContext.call.disconnect()
  }, [phoneDeviceContext])

  const onMute = useCallback(() => {
    phoneDeviceContext.call.mute(!phoneDeviceContext.call.isMuted())
  }, [phoneDeviceContext])

  const onApplicantNameClick = useCallback(() => {
    navigate(
      routes.applicantDetails({
        id: phoneDeviceContext.applicantId,
        tab: 'application',
      })
    )
  }, [phoneDeviceContext])

  const showCallButton = !phoneDeviceContext?.phoneDevice?.isBusy
  const showHangupButton = phoneDeviceContext?.phoneDevice?.isBusy

  const showDisplay =
    [
      CallStatus.INITIATED,
      CallStatus.RINGING,
      CallStatus.IN_PROGRESS,
      CallStatus.BUSY,
      CallStatus.NO_ANSWER,
      CallStatus.LEAVING_VOICEMAIL,
      CallStatus.LEFT_VOICEMAIL,
      CallStatus.FAILED,
    ].includes(phoneDeviceContext.callState) &&
    lastActivityLogId === phoneDeviceContext?.activityLogId

  const showSelectVoiceMail = !showDisplay

  const onSelectVoiceMailTemplate = useCallback(
    (selectedVoiceMailTemplateId) => {
      setSelectedVoiceMailTemplateId(selectedVoiceMailTemplateId)
    },
    []
  )

  const onClearVoiceMailTemplate = useCallback(() => {
    setSelectedVoiceMailTemplateId(null)
  }, [])

  const onPlayVoiceMail = useCallback(() => {
    if (selectedVoiceMailTemplateId) {
      playVoiceMail({
        variables: {
          input: {
            voiceMailTemplateId: selectedVoiceMailTemplateId,
            activityLogId: phoneDeviceContext?.activityLogId,
          },
        },
      })
    }
  }, [
    selectedVoiceMailTemplateId,
    phoneDeviceContext?.activityLogId,
    phoneDeviceContext?.call?.parameters?.CallSid,
    playVoiceMail,
  ])

  const showPlayVoicemailButton =
    selectedVoiceMailTemplateId &&
    (phoneDeviceContext?.callState === CallStatus.IN_PROGRESS ||
      phoneDeviceContext?.callState === CallStatus.LEAVING_VOICEMAIL) &&
    !loadingVoicemail

  return (
    <Draggable
      defaultClassName={`${
        phoneDeviceContext.openedDeviceModal
          ? 'bg-doubleNickel-gray-100 h-[72px] w-[400px] absolute right-5 top-5 rounded-lg p-4 z-[100] shadow-md'
          : 'hidden'
      }`}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-1 text-sm font-semibold text-doubleNickel-brand-500">
          <Box
            onClick={onApplicantNameClick}
            className="cursor-pointer hover:underline"
          >
            {phoneDeviceContext.applicantFullName}
          </Box>
          {showDisplay && (
            <div className="flex flex-row items-center gap-4">
              <CallStatusDisplay
                callState={phoneDeviceContext.callState}
                toNumber={phoneDeviceContext.phone}
                currentTime={elapsedTime}
              />
              {(phoneDeviceContext.callState === CallStatus.INITIATED ||
                phoneDeviceContext.callState === CallStatus.RINGING) && (
                <Loader color="blue" type="dots" size="sm" />
              )}
            </div>
          )}
          {showSelectVoiceMail && (
            <VoiceMailTemplateSelect
              selectedVoiceMailTemplateId={selectedVoiceMailTemplateId}
              onClear={onClearVoiceMailTemplate}
              onSelect={onSelectVoiceMailTemplate}
            />
          )}
          <LeaveVoicemailButton
            showPlayVoicemailButton={showPlayVoicemailButton}
            onPlayVoiceMail={onPlayVoiceMail}
            phoneDeviceContext={phoneDeviceContext}
          />
        </div>
        {showCallButton && (
          <CallCancelButton onCall={onCall} onCancel={onCancel} />
        )}
        {showHangupButton && (
          <HangupMuteButton onHangup={onHangup} onMute={onMute} />
        )}
        <MicPermissionDeniedModal isOpen={openedInstructions} onClose={close} />
      </div>
    </Draggable>
  )
}
