import { useEffect, useRef, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Box, Container, Divider, Loader } from '@mantine/core'
import { Image } from '@mantine/core'
import { useReactToPrint } from 'react-to-print'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import { useMutation } from '@redwoodjs/web'

import { PDFViewer } from 'src/components/DataDisplay/PDFViewer/PDFViewer'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_DOCUMENT_DOWNLOAD_URL,
  UPDATE_DOCUMENT,
} from 'src/graphql/documents.graphql'
import { FileContentType } from 'src/graphql/types/documents'
import { DocumentUserStatus } from 'src/graphql/types/documents'
import { downloadFile } from 'src/utils/downloadFile'

import DocumentEditScreen from './DocumentEditScreen/DocumentEditScreen'
import DocumentPreviewHeader from './DocumentPreviewHeader'
import ZoomControls from './ZoomControls/ZoomControls'

const imageTypes = [
  FileContentType.JPG,
  FileContentType.PNG,
  FileContentType.GIF,
  FileContentType.TIF,
  FileContentType.HEIC,
]

const supportedPreviewTypes = [...imageTypes, FileContentType.PDF]

const DocumentPreview = ({
  applicantId,
  documentId,
  showStatus = false,
  showBackButton = true,
  showClosebutton = false,
  onBack,
  showActions = true,
}) => {
  const componentRef = useRef()
  const [downloadedDocument, setDownloadedDocument] = useState(null)
  const [zoomedIn, setZoomedIn] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (documentId) {
      getDocumentUrlForPreview({
        variables: {
          id: documentId,
          applicantId: applicantId,
        },
      })
    }
  }, [documentId])

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: [GET_DOCUMENT_DOWNLOAD_URL],
    onCompleted: () => {
      toast('Document updated successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const [
    getDocumentUrlForPreview,
    {
      called: calledForPreview,
      data: documentForPreviewResponse,
      loading: loadingPreview,
    },
  ] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_URL, {
    onCompleted: () => {
      if (
        calledForPreview &&
        documentForPreviewResponse?.documentDownloadUrl?.url
      ) {
        setDownloadedDocument(documentForPreviewResponse.documentDownloadUrl)
      }
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const onPrint = () => {
    if (downloadedDocument.fileType === 'PDF') {
      onPrintPDF()
    } else if (imageTypes.includes(downloadedDocument.fileType)) {
      onPrintImage()
    } else {
      toast('Print is not available for this format.', 'warning')
    }
  }

  const onPrintImage = useReactToPrint({
    content: () => componentRef.current,
  })

  const onPrintPDF = () => {
    window.open(downloadedDocument.url, 'PRINT')
  }

  const handleDownload = () => {
    const { url, fileName } = downloadedDocument
    downloadFile({ url, fileName })
  }

  const handleApprove = () => {
    updateDocument({
      variables: {
        id: documentId,
        input: {
          userStatus:
            downloadedDocument.userStatus === DocumentUserStatus.PENDING_REVIEW
              ? DocumentUserStatus.COMPLETED
              : DocumentUserStatus.PENDING_REVIEW,
        },
      },
    })
    onBack()
  }

  if (!downloadedDocument) return null
  return (
    <div className="relative flex flex-1 flex-col gap-6 overflow-hidden">
      <DocumentPreviewHeader
        documentId={documentId}
        fileName={downloadedDocument.fileName}
        category={downloadedDocument.category}
        refetchQueries={[GET_DOCUMENT_DOWNLOAD_URL]}
        showActions={
          showActions &&
          downloadedDocument.userStatus == DocumentUserStatus.PENDING_REVIEW
        }
        showStatus={showStatus}
        showBackButton={showBackButton}
        showClosebutton={showClosebutton}
        setEditMode={setEditMode}
        onBack={() => onBack()}
        onPrint={onPrint}
        onDownload={handleDownload}
        onApprove={handleApprove}
      />
      <Divider />
      <Container fluid className="w-full flex-1 overflow-auto">
        {loadingPreview ? (
          <Loader />
        ) : editMode ? (
          <DocumentEditScreen
            document={downloadedDocument}
            documentId={documentId}
            onRender={[GET_DOCUMENT_DOWNLOAD_URL]}
            onCancel={() => setEditMode(false)}
          />
        ) : (
          <>
            {downloadedDocument.fileType === 'PDF' && (
              <PDFViewer url={downloadedDocument.url} />
            )}
            {imageTypes.includes(downloadedDocument.fileType) && (
              <TransformWrapper
                limitToBounds={false}
                centerZoomedOut
                wheel={{
                  wheelDisabled: true,
                  disabled: true,
                }}
                panning={{
                  wheelPanning: zoomedIn,
                }}
              >
                {({ zoomIn, resetTransform }) => (
                  <Box className="relative">
                    <div className="sticky top-0 z-50">
                      <ZoomControls
                        zoomIn={() => {
                          setZoomedIn(true)
                          zoomIn()
                        }}
                        zoomOut={() => {
                          resetTransform()
                          setZoomedIn(false)
                        }}
                      />
                    </div>
                    <TransformComponent>
                      <div
                        ref={componentRef}
                        className="flex items-center justify-center "
                      >
                        <Image
                          src={downloadedDocument.url}
                          alt={downloadedDocument.fileName}
                          h="auto"
                          w="auto"
                          fit="contain"
                        />
                      </div>
                    </TransformComponent>
                  </Box>
                )}
              </TransformWrapper>
            )}
          </>
        )}
        {!supportedPreviewTypes.includes(downloadedDocument.fileType) && (
          <Box className="flex h-96 items-center justify-center">
            <p className="text-gray-500">
              File preview is not available for this format. Please download the
              file to view its contents.
            </p>
          </Box>
        )}
      </Container>
    </div>
  )
}

export default DocumentPreview
