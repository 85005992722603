import { Badge, Tooltip, BadgeProps as MantineBadgeProps } from '@mantine/core'

interface BadgeProps extends MantineBadgeProps {
  tooltipText?: string
  color?: 'gray' | 'orange' | 'blue' | 'green' | 'red'
  textTransform?: 'uppercase' | 'none' | 'capitalize' | 'lowercase'
  clickable?: boolean
}

//TODO: replace color option with variants -- understand variant options better
const StyledBadge = ({
  tooltipText = '',
  textTransform = 'none',
  children,
  color = 'gray',
  clickable = false,
  ...props
}: BadgeProps) => {
  const addStrokeColor = () => {
    switch (color) {
      case 'orange':
        return 'bg-doubleNickel-warning-50 text-doubleNickel-warning-500 stroke-doubleNickel-warning-400'
      case 'blue':
        return 'bg-doubleNickel-brand-25 text-doubleNickel-brand-500 stroke-doubleNickel-brand-400'
      case 'green':
        return 'bg-doubleNickel-success-100 text-doubleNickel-success-600 stroke-doubleNickel-success-600'
      case 'red':
        return 'bg-doubleNickel-error-50 text-doubleNickel-error-500 stroke-doubleNickel-error-700'
      default:
        return 'bg-doubleNickel-gray-100 text-doubleNickel-gray-500 stroke-doubleNickel-gray-500'
    }
  }

  return (
    <div>
      <Tooltip
        label={tooltipText}
        withArrow
        disabled={!tooltipText}
        className="text-xs"
      >
        <Badge
          variant="light"
          className={`rounded-md border fill-none text-xs font-medium ${
            clickable && 'hover:shadow'
          }
          ${textTransform} ${addStrokeColor()}
        `}
          {...props}
        >
          {children}
        </Badge>
      </Tooltip>
    </div>
  )
}

export default StyledBadge
